<template>
  <div
    class="nav"
    :class="navBg"
    @mouseover="logoHover('mouseover')"
    @mouseout="logoHover('mouseout')"
  >
    <div class="nav-top">
      <a href="/"
        ><img
          :src="require(`@/assets/images/${logoUrl}`)"
          alt=""
          width="144px"
          class="logo"
      /></a>
      <ul>
        <li v-for="item in navData">
          <router-link :to="item.url" active-class="activeClass">
            <template v-if="item.down">
              <el-dropdown @command="secondaryClick">
                <span class="el-dropdown-link"
                  >{{ item.text }}<i class="el-icon-arrow-down"></i
                ></span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="val in item.dropdownItem"
                    :command="val.url"
                    >{{ val.text }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
            <template v-else>{{ item.text }}</template>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navBg: null,
      navData: [
        {
          text: "产品和解决方案",
          down: true,
          url: "",
          dropdownItem: [
            { text: "AI智能病历质控", url: "/quality" },
            { text: "AI智能辅助诊疗", url: "/diagnosis" },
          ],
        },
        {
          text: "精选案例",
          url: "/stories",
        },
        {
          text: "关于我们",
          url: "/aboutUs",
        },
      ],
      logoUrl: "logo.png",
      routeUrl: "",
    };
  },
  watch: {
    "$store.state.homeScrollTop"(val) {
      this.navStyle(val);
    },
    $route(val) {
      this.routeUrl = val.path;
      this.navStyle(this.$store.state.homeScrollTop);
    },
  },
  mounted() {
    this.routeUrl = this.$route.path;
    this.navStyle(this.$store.state.homeScrollTop);
  },
  methods: {
    logoHover(type) {
      if (this.navBg) return;
      if (type == "mouseover") {
        this.logoUrl = "logo-title.png";
      } else {
        this.logoUrl = "logo.png";
      }
    },
    secondaryClick(url) {
      this.$router.push({ path: url });
    },
    navStyle(val) {
      // if (this.routeUrl == "/stories") {
      //   this.navBg = "nav-top__bgcolor";
      //   this.logoUrl = "logo-title.png";
      // } else {
        this.navBg = val > 64 ? "nav-top__bgcolor" : "";
        this.logoUrl = this.navBg ? "logo-title.png" : "logo.png";
      // }
    },
  },
};
</script>

<style lang='scss'>
.nav {
  width: 100%;
  height: 64px;
  background-color: transparent;
  position: sticky;
  z-index: 101;
  top: 0;
  &:hover {
    background-color: #fff;
  }
  &:hover a {
    color: #333;
  }
  &:hover .el-dropdown-link {
    color: #333 !important;
  }
  &:hover .el-icon-arrow-down {
    color: #333;
  }
  &-top {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    // padding: 0 calc(50vw - 612px);
    ul {
      height: 100%;
      margin: 0;
      padding-left: 48px;
      li {
        display: inline-block;
        height: 100%;
        position: relative;
        .el-icon-arrow-down {
          color: #fff;
          margin-left: 8px;
        }
        &:hover > a::before {
          content: "";
          width: 48px;
          height: 4px;
          border-radius: 2px;
          background: #fbb726;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          display: inline-block;
          position: absolute;
        }
        a {
          display: inline-block;
          padding: 0 25px;
          line-height: 67px;
          color: #fff;
        }
      }
    }
    .logo {
      margin-top: 14px;
    }
    .el-dropdown-link:hover .el-icon-arrow-down {
      transform: rotate(180deg);
    }
    .el-dropdown-link {
      color: #fff;
      display: inline-block;
      height: 100%;
    }
  }
}
.nav-top__bgcolor {
  background-color: #fff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
  ul > li > a {
    color: #333;
  }
  .el-dropdown-link,
  .el-dropdown-link .el-icon-arrow-down {
    color: #333;
  }
}
</style>